<template>
  <div class="customized-course">
    <ul :class="['point-menu', { white: activeIndex > 0 }]">
      <li
        v-for="item in 4"
        :key="item"
        :class="{ active: item == activeIndex + 1 }"
        @click="moveTo(item)"
      ></li>
    </ul>
    <full-page ref="page" :options="options">
      <!--      第一屏-->
      <div class="section section1">
        <BreadCrumb />
        <!-- <div class="header">
          <div class="container">
            <span @click="$router.push('/college/collegeSetting')">书院管理</span>>><span
              @click="$router.push('/college/accountsManage')"
              >账户管理</span
            >>>定制线上课程
          </div>
        </div> -->
        <div class="content">
          <div class="container">
            <div class="title">课程定制开发</div>
            <ul class="des">
              <li>
                拥有强大的课题研发团队和丰富的课程研发经验
              </li>
              <li>
                专业化制作团队，一体化开发流程。周期可控，质量可信，做实力派
              </li>
              <li>
                主要服务于拥有大量面授讲师资源和精品面授主题课程的行业客户、高校客户。客户可以提供讲师资源，平台也可以帮助客户匹配讲师资源，我们将包办前后期策划、课程录制、后期制作等课程开发服务，打造“三专”
                高品质线上专题课程！
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--      第二屏-->
      <div class="section section2">
        <div class="content">
          <div class="container">
            <div class="title">高清拍摄</div>
            <div class="des">
              拥有先进的数字化录制硬件设备，具备丰富的全流程录制经验，打造数门精品课程，为用户和企业提供多重形式的拍摄手法，优质服务，精品课件形式内容，均位于行业领先水平
            </div>
            <div class="nav">
              <div class="name">
                <span>教法设计</span> <img src="@/assets/customizedCourse/arrow_down.png" alt="" />
              </div>
              <ul>
                <li>
                  <span>01</span>MOOC教学<img src="@/assets/customizedCourse/arrow_icon.png" />
                </li>
                <li>
                  <span>02</span>翻转课堂<img src="@/assets/customizedCourse/arrow_icon.png" />
                </li>
                <li>
                  <span>03</span>实践教学<img src="@/assets/customizedCourse/arrow_icon.png" />
                </li>
                <li>
                  <span>04</span>虚拟仿真<img src="@/assets/customizedCourse/arrow_icon.png" />
                </li>
                <li>
                  <span>05</span>线下金课教法<img src="@/assets/customizedCourse/arrow_icon.png" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="section section3">
        <div class="content">
          <div class="container">
            <div class="title">特效制作</div>
            <div class="des">
              拥有丰富的数据库图片视频资源，运用达芬奇、C4D、Premiere、AE、Edius、PS、Arctime等相关软件根据客户需求，整合素材，制作相关课件或宣传片。
            </div>
            <ul>
              <li v-for="(item, index) in navList" :key="index" @click="videoClick(item.video)">
                <div class="text">
                  <div class="name">{{ item.name }}</div>
                  <p>{{ item.en }}</p>
                </div>
                <img src="@/assets/customizedCourse/play_btn.png" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="section section4">
        <div class="content">
          <div class="container">
            <div class="title">服务范围</div>
            <img class="des_pic" src="@/assets/customizedCourse/s4_img.png" />
          </div>
        </div>
      </div>
    </full-page>
    <el-dialog custom-class="videoDialog" :visible.sync="videoVisible">
      <video v-if="videoVisible" autoplay controls :src="videoUrl"></video>
      <i class="close el-icon-circle-close" @click="videoVisible = false"></i>
    </el-dialog>
    <ul class="sideBar" :style="activeIndex > 0 ? 'height:120px' : 'height:60px'">
      <li :style="activeIndex > 0 ? '' : 'border:0'">
        <img src="@/assets/customizedCourse/kefu.png" alt="" />
        <img class="hover" src="@/assets/customizedCourse/kefu_hover.png" alt="" />
        <p>客服</p>
        <div class="tel">
          <img src="@/assets/customizedCourse/tel.png" alt="" />
          <span>17786424575</span>
        </div>
      </li>
      <li :style="activeIndex > 0 ? 'opacity:1' : 'opacity:0'" @click="moveTo(1)">
        <img src="@/assets/customizedCourse/backTop.png" alt="" />
        <p>置顶</p>
      </li>
    </ul>
  </div>
</template>

<script>
import BreadCrumb from '@/layout/components/BreadCrumb'
export default {
  name: 'CustomizedCourse',
  components: { BreadCrumb },
  data() {
    return {
      activeIndex: 1,
      options: {
        resize: true, //字体是否随着窗口缩放而缩放
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        scrollOverflow: true,
        afterLoad: this.afterLoad,
        scrollBar: false,
        keyboardScrolling: false, //是否可以使用键盘方向键导航，默认为true
        menu: '#menu',
        // paddingTop: '68px',
      },
      navList: [
        {
          name: '绿幕抠像',
          en: 'GREEN SCREEN KEYER',
          video:
            'https://hwvod.i-ronge.com/asset/51bfc4cd28c3a0da0697af1f7f30bae5/d5bf380bda0de8eb0f768e9cce7447d8.mp4',
        },
        {
          name: '场景实拍',
          en: 'SCENE TO FILM',
          video:
            'https://hwvod.i-ronge.com/asset/cf85f722e00e54ad94c0e220cdc6522d/49426d8a8fcd091d1c30779f0c36434d.mp4',
        },
        {
          name: '艺术处理',
          en: 'THE ART PROCESSING',
          video:
            'https://hwvod.i-ronge.com/asset/c02a0766e7a6750d6a7e4778a44d7939/7e69baa3c3761b0247d22926d4f73099.mp4',
        },
        {
          name: 'MG动画',
          en: 'MG ANIMATION',
          video:
            'https://hwvod.i-ronge.com/asset/76afec7c988fce7e24a2e5df88bdd5cd/de921d6a7657337dabb0996458dea5ca.mp4',
        },
        {
          name: '高清大屏',
          en: 'HD SCREEN',
          video:
            'https://hwvod.i-ronge.com/asset/ce72f8deb53b4e6b20892df7b6112797/4e0d4ab8b6eff448d016c776feda6d34.mp4',
        },
        {
          name: '虚拟仿真',
          en: 'VIRTUAL SIMULATION',
          video:
            'https://hwvod.i-ronge.com/asset/2ff14df198f559647989d62c53810713/b34f54efdb3a2a5b5b993663c5ddb4c0.mp4',
        },
        {
          name: '线上录播',
          en: 'THE ONLINE COPY AND PLAY',
          video:
            'https://hwvod.i-ronge.com/asset/6e20a75ef306e39b9e931703b5d2f044/f1a000c3582aa206c67d491c45fc76ff.mp4',
        },
        {
          name: '短视频',
          en: 'A SMALL VIDEO',
          video:
            'https://hwvod.i-ronge.com/asset/05113338c77954bb0faa144027ffdc09/18462e064f7d16fbf446f9e43128deb6.mp4',
        },
      ],
      videoVisible: false,
      videoUrl: '',
    }
  },
  methods: {
    videoClick(url) {
      this.videoUrl = url
      this.videoVisible = true
    },
    afterLoad(prev, next) {
      this.activeIndex = next.index
    },
    moveTo(item) {
      this.$refs.page.api.moveTo(item)
    },
  },
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: Alibaba-PuHuiTi-Bold;
  src: url('../../../../font/Alibaba-PuHuiTi-Bold.otf');
}
@font-face {
  font-family: Alibaba-PuHuiTi-Medium;
  src: url('../../../../font/Alibaba-PuHuiTi-Medium.otf');
}
@font-face {
  font-family: Alibaba-PuHuiTi-Regular;
  src: url('../../../../font/Alibaba-PuHuiTi-Regular.otf');
}
.customized-course {
  position: relative;
  .container {
    width: 1200px;
    margin: 0 auto;
  }
  .point-menu {
    z-index: 9999;
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    li {
      cursor: pointer;
      transition: all 0.3s;
      margin-bottom: 20px;
      border-radius: 50%;
      width: 19px;
      height: 19px;
      border: 1px solid #000000;
      &.active {
        background-color: #000000;
      }
    }
    &.white {
      li {
        border: 1px solid #fff;
        &.active {
          background-color: #fff;
        }
      }
    }
  }
  // background-color: #0a0a0a;
  .fullpage-wrapper {
    height: calc(100vh - 68px) !important;
  }
  .header {
    font-size: 20px;
    font-family: Alibaba-PuHuiTi-Regular;
    line-height: 52px;
    width: 100%;
    height: 52px;
    background: #0a0a0a;
    color: #ffffff;
    span {
      cursor: pointer;
    }
  }
  .section {
    box-sizing: border-box;
    &.section1 {
      ::v-deep.bread-crumb {
        position: absolute;
        padding: 20px 0 0 20px;
        span {
          color: #969696 !important;
        }
      }
      .content {
        height: 100%;
        background: url('../../../../assets/customizedCourse/s1_bg.png') no-repeat center center;
        background-size: cover;
        box-sizing: border-box;
        .title {
          padding-top: 18vh;
          font-size: 48px;
          font-family: Alibaba-PuHuiTi-Medium;
          font-weight: 500;
          color: #ffffff;
          line-height: 48px;
          letter-spacing: 1px;
          margin-bottom: 72px;
        }
        .des {
          width: 744px;
          font-size: 24px;
          font-family: Alibaba-PuHuiTi-Regular;
          color: #ffffff;
          line-height: 36px;
          li {
            position: relative;
            margin-bottom: 20px;
            padding-left: 20px;
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 12px;
              width: 11px;
              height: 11px;
              background: #ffffff;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &.section2 {
      .content {
        box-sizing: border-box;
        height: 100%;
        background: url('../../../../assets/customizedCourse/s2_bg.png') no-repeat center center;
        background-size: cover;
        padding-top: 4%;
        .title {
          position: relative;
          text-align: center;
          font-size: 48px;
          font-family: Alibaba-PuHuiTi-Bold;
          font-weight: 700;
          color: #fefefe;
          line-height: 48px;
          letter-spacing: 2px;
          width: 200px;
          height: 46px;
          margin: 0 auto;
          margin-bottom: 34px;
          &::before {
            position: absolute;
            left: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_left.png');
          }
          &::after {
            position: absolute;
            right: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_right.png');
          }
        }
        .des {
          font-size: 24px;
          font-family: Alibaba-PuHuiTi-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 36px;
          margin-bottom: 42px;
        }
        .nav {
          .name {
            box-sizing: border-box;
            padding: 0 40px 0 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 411px;
            height: 81px;
            background: #ffffff;
            font-size: 36px;
            font-family: Alibaba-PuHuiTi-Medium;
            font-weight: 500;
            color: #030001;
            line-height: 81px;
            margin-bottom: 32px;
          }
          li {
            padding-right: 48px;
            width: 411px;
            height: 35px;
            font-size: 48px;
            font-family: Alibaba-PuHuiTi-Regular;
            font-weight: 500;
            color: #bebebe;
            display: flex;
            align-items: center;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 49px;
            padding-left: 11px;
            box-sizing: border-box;
            span {
              font-size: 48px;
              font-family: Alibaba-PuHuiTi-Medium;
              font-weight: 500;
              color: #fff;
              margin-right: 30px;
            }
            img {
              margin-left: auto;
            }
          }
        }
      }
    }
    &.section3 {
      .content {
        box-sizing: border-box;
        height: 100%;
        background: url('../../../../assets/customizedCourse/s3_bg.png') no-repeat center center;
        background-size: cover;
        padding-top: 4%;
        .title {
          position: relative;
          text-align: center;
          font-size: 48px;
          font-family: Alibaba-PuHuiTi-Bold;
          font-weight: 700;
          color: #fff;
          line-height: 48px;
          letter-spacing: 2px;
          width: 200px;
          height: 46px;
          margin: 0 auto;
          margin-bottom: 34px;
          &::before {
            position: absolute;
            left: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_left.png');
          }
          &::after {
            position: absolute;
            right: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_right.png');
          }
        }
        .des {
          font-size: 24px;
          font-family: Alibaba-PuHuiTi-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 36px;
          margin-bottom: 68px;
        }
        ul {
          overflow: hidden;
          li {
            overflow: hidden;
            box-sizing: border-box;
            cursor: pointer;
            width: 295px;
            height: 166px;
            text-align: center;
            padding-top: 59px;
            float: left;
            margin-right: 6px;
            margin-bottom: 7px;
            &:nth-of-type(4n) {
              margin-right: 0;
            }
            &:hover {
              .text {
                transform: translateY(-120px);
              }
              > img {
                transform: translateY(-58px);
              }
            }
            .text {
              transition: all 0.3s ease-in-out;
            }
            > img {
              transition: all 0.3s ease-in-out;
              transform: translateY(120px);
            }
            .name {
              font-size: 31px;
              font-family: Alibaba-PuHuiTi-Medium;
              font-weight: 500;
              text-align: justifyLeft;
              color: #ffffff;
              line-height: 30px;
              letter-spacing: 2px;
              margin-bottom: 8px;
            }
            p {
              height: 10px;
              font-size: 12px;
              font-weight: 500;
              text-align: center;
              color: #ffffff;
              line-height: 10px;
            }
            &:nth-of-type(1) {
              background: url('../../../../assets/customizedCourse/s3_1.png') no-repeat;
            }
            &:nth-of-type(2) {
              background: url('../../../../assets/customizedCourse/s3_2.png') no-repeat;
            }
            &:nth-of-type(3) {
              background: url('../../../../assets/customizedCourse/s3_3.png') no-repeat;
            }
            &:nth-of-type(4) {
              background: url('../../../../assets/customizedCourse/s3_4.png') no-repeat;
            }
            &:nth-of-type(5) {
              background: url('../../../../assets/customizedCourse/s3_5.png') no-repeat;
            }
            &:nth-of-type(6) {
              background: url('../../../../assets/customizedCourse/s3_6.png') no-repeat;
            }
            &:nth-of-type(7) {
              background: url('../../../../assets/customizedCourse/s3_7.png') no-repeat;
            }
            &:nth-of-type(8) {
              background: url('../../../../assets/customizedCourse/s3_8.png') no-repeat;
            }
          }
        }
      }
    }
    &.section4 {
      .content {
        box-sizing: border-box;
        height: 100%;
        background: url('../../../../assets/customizedCourse/s4_bg.png') no-repeat center center;
        background-size: cover;
        padding-top: 4%;
        text-align: center;
        .title {
          position: relative;
          text-align: center;
          font-size: 48px;
          font-family: Alibaba-PuHuiTi-Bold;
          font-weight: 700;
          color: #fff;
          line-height: 48px;
          letter-spacing: 2px;
          width: 200px;
          height: 46px;
          margin: 0 auto;
          margin-bottom: 81px;
          &::before {
            position: absolute;
            left: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_left.png');
          }
          &::after {
            position: absolute;
            right: -170px;
            top: 10px;
            content: '';
            width: 115px;
            height: 25px;
            background: url('../../../../assets/customizedCourse/title_right.png');
          }
        }
        .des_img {
          width: 1124px;
          height: 525px;
        }
      }
    }
  }
  ::v-deep .videoDialog {
    width: 1000px;
    height: 562px;
    background-color: #000;
    position: relative;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    video {
      display: block;
      width: 1000px;
      height: 562px;
    }
    .close {
      font-size: 50px;
      cursor: pointer;
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
    }
  }
  .sideBar {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: fixed;
    bottom: 175px;
    right: 57px;
    width: 60px;
    height: 120px;
    background: #ffffff;
    border: 1px solid #7e7e7e;
    border-radius: 4px;
    padding: 0 4px;
    box-sizing: border-box;
    &:hover {
      overflow: visible;
    }
    li {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      border-bottom: 1px solid #909399;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 26px;
        height: 25px;
        margin-bottom: 5px;
      }
      img.hover {
        display: none;
      }
      &:first-child {
        position: relative;
        padding-top: 6px;
        &:hover {
          transition: all 0.3s;
          position: relative;
          width: 60px;
          height: 60px;
          background: #fca139;
          border-radius: 4px;
          left: -5px;
          top: -1px;
          border: 0;
          p {
            color: #fff;
          }
          > img {
            display: none;
            &.hover {
              display: block;
            }
          }
          .tel {
            transition: all 0.3s;
            opacity: 1;
            right: 60px;
          }
        }
        .tel {
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 30px;
          top: 7px;
          width: 180px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #7e7e7e;
          border-radius: 4px;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;
          img {
            width: 22px;
            height: 22px;
            margin-right: 6px;
            margin-bottom: 0;
          }
          span {
            font-size: 20px;
            font-family: Alibaba-PuHuiTi-Regular;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 46px;
          }
        }
      }
      &:last-child {
        padding-top: 7px;
        border: 0;
        img {
          width: 20px;
          height: 22px;
          margin-bottom: 8px;
        }
      }
      p {
        font-size: 16px;
        font-family: Alibaba-PuHuiTi-Regular;
        font-weight: 400;
        text-align: center;
        color: #909399;
        line-height: 16px;
      }
    }
  }
}
</style>
